<template>
	<div class="Links">


	<div class="Links--text">

		Bonjour! <br><br>

		Vous pouvez télécharger mon cv et portfolio via les liens suivants :

	</div>

	<div class="Links--links">

		<a 
			class="link-link"

			:href="state.cv_link"
			target="_blank"
		>
			<p>Télécharger le CV</p>
		</a>

		<a 
			class="link-link"
			:href="state.portfolio_link"
			target="_blank"
		>
			<p>Télécharger le Portfolio</p>
		</a>

	</div>

	<div class="Links--contact">
		<br>
		<p>N'hésitez pas à me contacter pour toute information.</p>
		<p>
			Julien Gatard <br>
			+33 6 27 39 92 75 <br>
			<A HREF="mailto:info@juliengatard.fr">info@juliengatard.fr</A>
		</p>

	</div>


		<!-- <pre>{{ state.portfolio_link }}</pre> -->

	</div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'Links',
  mixins: [clientConfigs],
  data: function(){
    return {
      state : this.$store.state,
      viewerDisplay: false
    }
  },
  watch: {},
	computed: {
		page: function () { return this.$store.state},
	},
	methods: {},
	created(){},
	mounted(){}
}
</script>

<style lang="scss">

.Links{
	margin: 12px;
	font-family: monospace;
}



.Links{
	cursor: default;
	display: flex;
	flex-direction: column;

	& a{
		margin-right: 6px;
	}
}

.link-link{

	& p { 
		display: inline-block;
		cursor: pointer;
		background-color: black;
		color: white;

		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 32px;
	}
}



.Links--links, .Links--contact p{
	margin-top: 10px;
}

.Links--contact a{
	text-decoration: underline;
}

</style>
